<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent">

                <spinner v-show="loading"></spinner>

                <article class="tile is-child box" v-if="!loading && item">

                    <form class="block" @submit.prevent="saveAndRedirect">
                        <h1 class="title">Добавление
                            <button v-if="this.item.proof_url !== ''" class="button is-primary is-pulled-right" :class="{ 'is-loading': saving }">
                                Сохранить
                            </button>
                        </h1>
                        <div class="control">
                            <table class="table">
                                <thead>
                                <tr>
                                    <th>Ваши баллы на {{ this.date }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <input class="input" type="text" style="width: 300px" placeholder="0" v-model.trim="item.points">
                                        <input type="hidden" v-model.trim="item.proof_url">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="file"  ref="file" :name="file" @change="onFileSelected($event.target.files)">
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </form>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import CmeService from '../../services/cme'
import Axios from 'axios'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import moment from 'moment'

export default {
  data () {
    return {
      selectedFile: null,
      date: moment(String(new Date())).format('DD.MM.YYYY')
    }
  },
  mixins: [createOrUpdateViewMixin(CmeService, {
    proof_url: null,
    points: 0
  })],
  methods: {
    saveAndRedirect () {
      this.save().then(() => {
        this.$router.push({ name: 'Cmes' })
      })
    },
    onFileSelected (files) {
      this.selectedFile = files[0]
      let fd = new FormData()
      fd.append('file', this.selectedFile, this.selectedFile.name)

      Axios.post('/api/cme/upload', fd).then(res => {
        this.item.proof_url = res.data
      })
    }
  }
}
</script>
